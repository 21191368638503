import React, { useState, useContext, useEffect, useRef } from "react";
import Proptypes from "prop-types";
import { Col, Form, Input, Row, Button, Select, Radio, Checkbox } from "antd";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import PhoneInputAntd from "../../common/PhoneInputAntd";
import { TITLES, DILIGENCE_FORMS, USER_STATUS } from "../../../utils/constants";
import { updateUser, checkEmail } from "../../../utils/api";
import {
  UserDispatchContext,
  UserStateContext,
} from "../../../context/UserContextProvider";
import { CountrySearch } from "../../common/CountrySearch";
import {
  AuthDispatchContext,
  AuthStateContext,
} from "../../../context/AuthContextProvider";

function SignupCommon({ afterSubmit }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [hydrationDone, setHydrationDone] = useState(false);
  const UserDispatch = useContext(UserDispatchContext);
  const AuthDispatch = useContext(AuthDispatchContext);
  const { user } = useContext(UserStateContext);
  const { accessToken: token } = useContext(AuthStateContext);
  const phoneRef = useRef();

  useEffect(() => {
    const flagDropDownElements = document.getElementsByClassName(
      "flag-dropdown"
    );

    if (flagDropDownElements[0]) {
      flagDropDownElements[0].setAttribute("tabindex", "-1");
    }
  }, []);

  useEffect(() => {
    if (hydrationDone) return;
    setHydrationDone(true);
    // phoneRef.current.setCountry("sg");
    if (user) {
      if (user.status === USER_STATUS.VALID)
        navigate("/register/setup-diligence-form");
      const {
        title,
        fname,
        lname,
        countryOfResidence,
        phone,
        email,
        selectedDiligenceForm,
      } = user;
      form.setFieldsValue({
        title,
        fname,
        lname,
        countryOfResidence,
        phone,
        email,
        selectedDiligenceForm,
      });
      if (!phone)
        // @ts-ignore
        phoneRef.current.setCountry((countryOfResidence || "sg").toLowerCase());
    } else {
      // @ts-ignore
      phoneRef.current.setCountry("sg");
    }
  }, [user]);

  const syncData = (onSuccess?, extra = {}) => {
    const values = form.getFieldsValue();

    if (values.title) values.title = values.title.toLowerCase();

    const {
      email,
      phone,
      title,
      lname,
      fname,
      countryOfResidence,
      selectedDiligenceForm,
    } = values;

    const valuesOfInterrest = {
      email,
      phone,
      title,
      lname,
      fname,
      countryOfResidence,
      selectedDiligenceForm,
      ...extra,
    };

    if (token !== null && user && user.id) {
      updateUser(user.id, token, valuesOfInterrest).then(async r => {
        if (r.ok) {
          const { user: newUser } = await r.json();
          if (onSuccess) onSuccess({ user: newUser });
        }
      });
    }
  };
  const { t } = useTranslation();
  return (
    <div className="signup_slide">
      <h1 className="section-title">{t("signup_start_registration")}</h1>

      <Form
        form={form}
        layout="vertical"
        name="register"
        size="large"
        onBlur={() => {
          syncData();
        }}
        onFinish={() => {
          setIsLoading(true);
          syncData(
            r => {
              if (r) {
                AuthDispatch({ type: "set", payload: r });
                UserDispatch({ type: "set", payload: r });
              }
              if (afterSubmit) afterSubmit();
            },
            { sendPhoneCode: true }
          );
        }}
        scrollToFirstError
      >
        <Row gutter={20}>
          <Col xs={24} md={4}>
            <Form.Item
              label={t("title.lbl")}
              name="title"
              initialValue="mr"
              rules={[{ required: true, message: t("title.err.required") }]}
            >
              <Select placeholder={t("title.plh")}>
                {Object.values(TITLES).map(e => (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              label={t("fname.lbl")}
              name="fname"
              rules={[{ required: true, message: t("fname.err.required") }]}
            >
              <Input placeholder={t("fname.plh")} />
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              label={t("lname.lbl")}
              name="lname"
              rules={[{ required: true, message: t("lname.err.required") }]}
            >
              <Input placeholder={t("lname.plh")} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t("country.lbl")}
          name="countryOfResidence"
          initialValue="SG"
          rules={[{ required: true, message: t("country.err.required") }]}
        >
          <CountrySearch
            placeholder={t("country.plh")}
            onChange={c => {
              if (phoneRef.current)
                // @ts-ignore
                phoneRef.current.setCountry(c.toLowerCase());
            }}
          />
        </Form.Item>
        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Form.Item shouldUpdate>
              {() => (
                <Form.Item
                  label={t("phone.lbl")}
                  name="phone"
                  getValueFromEvent={e => e}
                  rules={[{ required: true, message: t("phone.err.required") }]}
                  style={{ marginBottom: 0 }}
                >
                  <PhoneInputAntd ref={phoneRef} />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("email.lbl")}
              name="email"
              validateTrigger="onBlur"
              rules={[
                {
                  validator: (r, v) => {
                    // eslint-disable-next-line no-async-promise-executor
                    return new Promise(async (res, rej) => {
                      if (token) {
                        if (v) {
                          try {
                            const result = await checkEmail({
                              token,
                              email: v,
                            });
                            if (result.ok) {
                              const rr = await result.json();
                              if (rr.success) {
                                res();
                                return;
                              }
                            }
                            rej(t("email.err.exist"));
                          } catch (e) {
                            console.log(e);
                            rej(t("email.err.exist"));
                          }
                        } else {
                          res();
                        }
                      }
                    });
                  },
                },
                { type: "email", message: t("email.err.invalid") },
                { required: true, message: t("email.err.required") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <Form.Item
          name="selectedDiligenceForm"
          label={t("signup_diligence_selection_title")}
          className="label-title"
          initialValue={DILIGENCE_FORMS.INDIVIDUAL}
        >
          <Radio.Group className="diligenceType" style={{ marginTop: 20 }}>
            <Radio value={DILIGENCE_FORMS.INDIVIDUAL}>
              <div className="circle" />
              {t("diligence.indiv")}
            </Radio>
            <Radio value={DILIGENCE_FORMS.CORPORATE_ENTITY}>
              <div className="circle" />
              {t("diligence.corp")}
            </Radio>
            <Radio value={DILIGENCE_FORMS.TRUST_FOUNDATION}>
              <div className="circle" />
              {t("diligence.trust")}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Row align="middle" justify="center">
          <Form.Item name="agree" valuePropName="checked" noStyle>
            <Checkbox className="cond-use">
              <div style={{ paddingLeft: 20 }}>{t("agree_cond_msg")}</div>
            </Checkbox>
          </Form.Item>
        </Row>
        <Row
          style={{
            color: "black",
            paddingTop: 20,
            opacity: 0.65,
            fontSize: 14,
          }}
        >
          <div className="section-note">
            <p>{t("agree_cond_desc")}</p>
          </div>
        </Row>
        <Row style={{ marginTop: "34px" }} gutter={24}>
          <Col
            xs={24}
            md={{ span: 12, offset: 12 }}
            style={{ textAlign: "right" }}
          >
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.agree !== curValues.agree
              }
            >
              {() => {
                return (
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => {
                        form.submit();
                        // afterSubmit();
                      }}
                      disabled={!form.getFieldValue("agree")}
                      loading={isLoading}
                      className="main-btn"
                    >
                      {t("continue")}
                    </Button>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

SignupCommon.propTypes = {
  afterSubmit: Proptypes.func,
  onDiligenceFormChange: Proptypes.func,
};

export default SignupCommon;
