import React from "react";
import Layout from "../../components/Layout";
// import { Layout } from "antd";
import { UserSignup } from "../../components/signup/UserSignup";
import { UserSignupHeader } from "../../components/Headers/UserSignupHeader";
import "../../styles/register.scss";
import SEO from "../../components/SEO/SEO";

function Register() {
  return (
    <Layout header={<UserSignupHeader />}>
      <SEO title="Check your eligibility" desc="Join in today and check your eligibility!" />
      <div className="signup_container">
        <UserSignup />
      </div>
    </Layout>
  );
}

export default Register;
