/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from "react";
import Proptypes from "prop-types";
import { message, Result } from "antd";
import { useTranslation } from "react-i18next";
import { UserStateContext } from "../../../context/UserContextProvider";
import { setEmailValidation } from "../../../utils/api";
import { AuthStateContext } from "../../../context/AuthContextProvider";
// @ts-ignore
import SendIcon from "../../../images/email-sent.svg";

export function VerifyMailSlide() {
  const { user } = useContext(UserStateContext);
  const { accessToken } = useContext(AuthStateContext);
  const [localToken, setLocalToken] = useState(null);
  const [localEmail, setLocalEmail] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (accessToken) setLocalToken(accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (user && user.email) setLocalEmail(user.email);
  }, [user && user.email]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F9FAF7",
        border: "1px solid #D7C596",
        borderRadius: "4px",
        padding: 20,
      }}
    >
      <div className="email-verification__header">{t("verif_email.title")}</div>
      <Result
        icon={<img src={SendIcon} alt="send" width="80px" />}
        subTitle={`${t("verif_email.msg")} ${localEmail || ""}.`}
        extra={
          <div className="email-verification__footer">
            {t("verif_email.didnot_receive")}{" "}
            <span
              className="link"
              onClick={() => {
                setEmailValidation({ token: localToken }).then(() => {
                  message.success(t("verif_email.success"));
                });
              }}
            >
              {t("verif_email.send_again")}
            </span>
          </div>
        }
      />
    </div>
  );
}

VerifyMailSlide.propTypes = { onSuccess: Proptypes.func };
