import React, { useState, useEffect, useCallback } from "react";
import gsap from "gsap";
import Proptypes from "prop-types";
import { navigate } from "gatsby";
import Common from "./common";
import { PhoneSecretSlide } from "./PhoneSecretSlide";
import { VerifyMailSlide } from "./VerifyMailSlide";
import { useSyncUser, useReset } from "../../../utils/hooks";
import { eraseAllCookies } from "../../../utils/eraseAllCookies";
import { getCookie } from "../../../utils/getCookie";
import Loader from "../../common/Loader";

const Slide = ({ children, style = {} }) => (
  <div
    className="slide"
    style={{
      width: "100%",
      // height: "100%",
      position: "absolute",
      top: 0,
      // left: 0,
      opacity: 0,
      display: "none",
      left: "100%",

      ...style,
    }}
  >
    {children}
  </div>
);
Slide.propTypes = { children: Proptypes.any, style: Proptypes.any };

export function UserSignup() {
  const [oldStep, setOldStep] = useState(-1);
  const [step, setStep] = useState(0);
  const reset = useReset();
  const [, forceUpdate] = useState(false);
  useEffect(() => {
    if (getCookie("done")) {
      reset();
      navigate("/");
    }
  }, []);
  useEffect(() => {
    // if (accessToken && user && user.id) {
    //   updateUser(user.id, accessToken, { step });
    // }
    if (step === 2) {
      reset();
    }
  }, [step, reset]);

  const cleanCookies = useCallback(() => {
    eraseAllCookies();
    forceUpdate(o => !o);
  }, []);
  const [isLoading] = useSyncUser({
    getToken: getCookie("accessToken"),
    onFail: cleanCookies,
    createUser: true,
  });

  useEffect(() => {
    if (isLoading === false) {
      const slides = Array.from(document.getElementsByClassName("slide"));
      if (slides[step]) {
        gsap.set(slides[step], { display: "initial" });
        gsap.to(slides[step], {
          duration: 0.5,
          left: 0,
          opacity: 1,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    const slides = Array.from(document.getElementsByClassName("slide"));
    if (slides[step]) {
      gsap.set(slides[step], { display: "initial" });
      gsap.to(slides[step], {
        duration: 0.5,
        left: 0,
        opacity: 1,
        // rotation: 360,
        // ease: "none",
        // paused: true,
      });
      // window.scrollTo(0, 0);
    }

    // const slides = Array.from(document.getElementsByClassName("slide"));
    if (slides[oldStep]) {
      gsap.to(slides[oldStep], {
        duration: 0.5,
        left: "-100%",
        opacity: 0,
        // rotation: 360,
        // ease: "none",
        // paused: true,
        onComplete: () => {
          gsap.set(slides[oldStep], { display: "none" });
        },
      });
    }
  }, [step, oldStep]);

  const incStep = useCallback(
    () =>
      setStep(o => {
        setOldStep(o);
        return o + 1;
      }),
    []
  );

  if (isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  return (
    <div
      className="animform"
      style={{
        display: "block",
        position: "relative",
        // overflow: "hidden",
        height: "",
        width: "100%",
      }}
    >
      <Slide>
        <Common
          afterSubmit={() => {
            incStep();
          }}
        />
      </Slide>

      <Slide>
        <PhoneSecretSlide onSuccess={incStep} />
      </Slide>

      <Slide>
        <VerifyMailSlide />
      </Slide>
    </div>
  );
}
