import React, { useMemo } from "react";
import { Select } from "antd";
import { COUNTRIES_LIST } from "../../utils/constants";

export function CountrySearch({
  remove,
  valueP,
  value,
  placeholder = "Select a Country",
  ...props
}: any) {
  const list = useMemo(() => {
    if (remove && remove.length > 0)
      return COUNTRIES_LIST.filter(e => remove.indexOf(e.code) === -1);
    return COUNTRIES_LIST;
  }, [remove]);
  const v = valueP || value;

  return (
    <Select
      // placeholder="Select a Country"
      placeholder={placeholder}
      //   allowClear
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
      value={v}
    >
      {list.map(c => (
        <Select.Option key={c.code} value={c.code}>
          {c.name}
        </Select.Option>
      ))}
    </Select>
  );
}
