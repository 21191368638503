/* eslint-disable no-restricted-globals */
import React, { useContext, useState, useEffect } from "react";
import Proptypes from "prop-types";
import { Row, message } from "antd";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import { UserStateContext } from "../../../context/UserContextProvider";
import {
  updateUser,
  verifPhoneCode,
  setEmailValidation,
} from "../../../utils/api";
import { AuthStateContext } from "../../../context/AuthContextProvider";
// @ts-ignore
import back from "../../../images/back.svg";

const initialValues = ["", "", "", "", "", ""];

export function PhoneSecretSlide({ onSuccess }) {
  const { user } = useContext(UserStateContext);
  const [digits, setDigits] = useState(initialValues);
  const { accessToken } = useContext(AuthStateContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (digits.reduce((acc, d) => acc && d.length > 0, true)) {
      verifPhoneCode({ token: accessToken, code: digits.join("") }).then(
        async r => {
          if (r.ok) {
            const data = await r.json();
            if (data.success) {
              message.success("Phone number was verified with success");
              if (onSuccess) onSuccess();
              setEmailValidation({ token: accessToken });
            }
          }
        }
      );
    }
  }, [digits]);

  return (
    <div className="phoneSecretSlide">
      <div className="phoneSecretSlide__firstTitle">
        {t("verif_phone.title")}
      </div>

      <div className="phoneSecretSlide__container">
        <div className="phoneSecretSlide__header">
          <Link to="/" className="phoneSecretSlide__button">
            <img src={back} alt="" />
            <div className="phoneSecretSlide__buttonText">{t("back")}</div>
          </Link>
          <div className="phoneSecretSlide__title text-uppercase">
            {t("verif_phone.send_to")}{" "}
            <span style={{ color: "#c2a066" }}>
              {" "}
              {(user && user.phone) || "your phone"}
            </span>
          </div>
        </div>
        <Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{ padding: 20 }}>
          {digits.map((elm, i) => (
            <input
              key={`ed${i}`}
              className="digit_field phoneSecretSlide__rectangle"
              onPaste={e => {
                e.preventDefault();
                e.stopPropagation();
                e.persist();

                const data = e.clipboardData
                  .getData("text")
                  .trim()
                  .split("")
                  .filter(it => it); // || window.clipboardData
                if (
                  data.length > 0 &&
                  data.reduce((acc, c) => acc && !isNaN(parseInt(c, 10)), true)
                ) {
                  if (data.length === 6) {
                    setDigits(data);
                  } else {
                    const newDigits = [...digits];
                    for (let j = i; j < 6; j += 1) {
                      if (data[j - i]) newDigits[j] = data[j - i];
                    }
                    setDigits(newDigits);
                  }
                }
              }}
              value={elm}
              onChange={e => {
                e.persist();
                const val = e.target.value;
                const newDigit = parseInt(val.slice(-1), 10);
                if (!val) {
                  setDigits(o => {
                    const oo = o;
                    oo[i] = ``;
                    return [...oo];
                  });
                }
                if (!isNaN(newDigit)) {
                  setDigits(o => {
                    setTimeout(() => {
                      const inputs = document.getElementsByClassName(
                        "digit_field"
                      );
                      // @ts-ignore
                      if (inputs[i + 1]) inputs[i + 1].focus();
                      // @ts-ignore
                      // else if (inputs[i]) inputs[i].blur();
                    }, 10);
                    const oo = o;
                    oo[i] = `${newDigit}`;
                    return [...oo];
                  });
                }
              }}
              onKeyDown={e => {
                if (e.key === "Backspace" && digits[i] === "") {
                  const inputs = document.getElementsByClassName("digit_field");
                  if (inputs[i - 1]) {
                    // @ts-ignore
                    inputs[i - 1].focus();
                    // @ts-ignore
                    inputs[i - 1].value = digits[i - 1];
                  }
                }
                // else if (e.key === "ArrowRight") {
                //   const inputs = document.getElementsByClassName("digit_field");
                //   if (inputs[i + 1]) {
                //     inputs[i + 1].focus();
                //   }
                // } else if (e.key === "ArrowLeft") {
                //   const inputs = document.getElementsByClassName("digit_field");
                //   if (inputs[i - 1]) {
                //     inputs[i - 1].focus();
                //     inputs[i - 1].setSelectionRange(-1, -1);
                //   }
                // }
              }}
            />
          ))}
        </Row>
        <div className="phoneSecretSlide__textFooter">
          {t("verif_phone.didnot_receive")}{" "}
          <span
            className="link"
            onClick={() => {
              updateUser(user.id, accessToken, { sendPhoneCode: true }).then(
                () => {
                  message.success(t("verif_phone.success"));
                }
              );
            }}
          >
            {t("verif_phone.send_again")}
          </span>
        </div>
        {/* {user && user.phoneVerifCode} */}
      </div>
    </div>
  );
}

PhoneSecretSlide.propTypes = { onSuccess: Proptypes.func };
